<template>
    <div class="wraper">
        <div class="wrap-mainer">
            <div class="numsidebar">
                <div class="sidebarmain">
                    <a-tabs v-model="active_key" tab-position="left" type="card">
                        <a-tab-pane :key="item.id" :tab="item.meta_title" v-for="(item,key) in Get_admin_menu_list"></a-tab-pane>
                    </a-tabs>
                </div>
            </div>
            <div class="list-right wrap-container">
                <div v-if="show_data">
                    <a-row>
                        <a-col :span="12">
                            <b><span class="text-sm">一级菜单</span></b>
                            <table class="layui-table" lay-size="sm">
                                <thead>
                                <tr>
                                    <th style="width: 35%">菜单名称</th>
                                    <th style="width: 35%">是否显示</th>
                                    <th style="width: 15%">排序</th>
                                    <th style="width: 15%">操作</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <a-input v-model="show_data.meta_title"></a-input>


                                    </td>
                                    <td>
                                        <a-radio-group v-model="show_data.show" :default-value="1">
                                            <a-radio :value="1">是</a-radio>
                                            <a-radio :value="2">否</a-radio>
                                        </a-radio-group>
                                    </td>
                                    <td>
                                        <a-input v-model="show_data.order"></a-input>
                                    </td>
                                    <td>
                                        <a-button type="link" @click="saveClick(show_data)">保存</a-button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <b><span class="text-sm">二级菜单</span></b>
                            <table class="layui-table" lay-size="sm">
                                <thead>
                                <tr>
                                    <th style="width: 25%">菜单名称</th>
                                    <th style="width: 25%">切换到其他分类</th>
                                    <th style="width: 30%">是否显示</th>
                                    <th style="width: 10%">排序</th>
                                    <th style="width: 10%">操作</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,key) in show_data.children">
                                    <td>
                                        <a-input v-model="item.meta_title"></a-input>
                                    </td>
                                    <td>

                                        <a-select placeholder="请选择" style="width: 200px" v-model="item.pid">
                                            <a-select-option v-for="(i,key ) in Get_admin_menu_list" :key="key" :value="i.id">
                                                {{ i.meta_title}}
                                            </a-select-option>
                                        </a-select>

                                    </td>
                                    <td>
                                        <a-radio-group v-model="item.show" :default-value="1">
                                            <a-radio :value="1">是</a-radio>
                                            <a-radio :value="2">否</a-radio>
                                        </a-radio-group>
                                    </td>
                                    <td>
                                        <a-input v-model="item.order"></a-input>
                                    </td>
                                    <td>
                                        <a-button type="link" @click="isaveClick(item)">保存</a-button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </a-col>
                    </a-row>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    export default {
        name: "work",
        components: {},
        data() {
            return {
                active_key: 0,
                Get_admin_menu_list: [],
                iGet_admin_menu_list: [],
                meta_title: '',
                show: '',
                order: '',
                page_do: false,
                show_data: false,
                fenleilist: []
            }
        },
        watch: {
            $route: {
                handler() {
                    this.mountedDo()
                },
                deep: true
            },
            active_key(val) {
                if (this.page_do) {
                    this.$router.push('/setxitong/menuset?ak=' + val)
                }
            },
        },
        mounted() {
            this.mountedDo()
        },
        methods: {


            searchData() {
                let info = false
                this.Get_admin_menu_list.map((item) => {
                    if (this.active_key === item.id) {
                        info = item
                    }
                })
                this.show_data = info
            },


            mountedDo() {
                this.active_key = Number(this.$route.query.ak) || 0
                this.Get_admin_menu()
            },
            // w获取导航列表
            Get_admin_menu() {
                this.$sa0.post({
                    url: this.$api('Get_admin_menu'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_admin_menu_list = response.data.list1;

                            if (this.active_key === 0) {
                                this.active_key = this.Get_admin_menu_list[0]['id']
                            }
                            this.page_do = true
                            this.searchData()

                            this.meta_title = this.Get_admin_menu_list.meta_title;
                            this.show = this.Get_admin_menu_list.show;
                            this.order = this.Get_admin_menu_list.order;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // w设置导航
            saveClick(show_data) {
                let data = {
                    admin_auth_id: show_data.id,
                    meta_title: show_data.meta_title,
                    show: show_data.show,
                    order: show_data.order,
                }
                //console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Set_menu'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg('已保存')
                            window.location.reload()
                            //this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
            // w设置导航
            isaveClick(item) {
                // if (item.pid.value != item.pid.value){
                //
                // }

                let data = {
                    admin_auth_id: item.id,
                    meta_title: item.meta_title,
                    show: item.show,
                    order: item.order,
                    pid: item.pid,
                }
                //console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Set_menu'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg('已保存')

                            this.$sa0.post({
                                url: this.$api('Move_admin_menu'),
                                data: {
                                    id: item.id,
                                    pid: item.pid
                                }
                            }).then((response) => {
                                this.$sa0.response({
                                    response: response.data,
                                    then: (res) => {
                                        layer.msg('设置成功')
                                        window.location.reload()
                                    },
                                    error: (res) => {
                                        layer.msg(res.message)
                                    }
                                })
                            })
                            //this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

        }

    }
</script>

<style>
    .list-left{
        background: #f9f9f9;
        min-height: 850px;
        border: 1px solid #eeeeee;
        border-right: none;
        height: 100%;
        padding: 20px 0 0 10px
        }

    .list-right{
        padding-left: 220px;

        }

    .ant-tabs .ant-tabs-left-bar{
        float: right !important;
        margin-right: -1px;
        margin-bottom: 0;
        border-right: 1px solid #e8e8e8;
        width: 100% !important;
        }

    .ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active{
        margin-right: -1px;
        padding-right: 40px !important;
        font-weight: bold;
        }

    .addbutton{
        width: 100%;
        text-align: center;
        margin-left: -5px;
        }

    .wraper{
        width: 100%;
        height: 100%;
        display: flex;
        }

    .wrap-mainer{
        flex: 1;
        display: flex;
        }

    .numsidebar{ position: fixed; width: 200px; display: flex; flex-direction: column; background: #f9f9f9; padding: 10px; padding-right: 0; height: calc(100vh - 120px)}

    .sidebarmain{
        flex: 1;
        overflow-y: auto
        }

    .wrap-container{
        flex: 1;
        overflow-y: auto
        }
</style>
